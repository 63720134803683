import { useSignal } from "@preact/signals";

export default function RegisterForm({ changeForm, changeData }: { changeForm: (formName: string) => void, changeData: (l: string, p: string, c: string) => void }) {
    const login = useSignal("")
    const password = useSignal("")
    const repeatPassword = useSignal("")
    const errorReason = useSignal("")

    async function register() {
        if ((login.value && password.value && repeatPassword.value &&
            password.value == repeatPassword.value && password.value.length >= 6)) {
            const result = await fetch("/api/login", {
                method: "PUT",
                body: JSON.stringify({
                    login: login.value,
                    password: password.value,
                    repeatPassword: repeatPassword.value
                })
            })

            const data = await result.json()
            if (!data.success)
                errorReason.value = data.reason
            else {
                changeData(data.login, data.password, data.code)
                changeForm("checkCode")
            }

        }
    }

    return (
        <div class="card mx-auto my-2 register shadow max-w-[400px]">
            <div class="card-body d-flex flex-column justify-content-between align-items-center gap-2">
                <div>
                    <h4 class="text-center mb-0 text-2xl font-semibold">Регистрация</h4>
                    <p class="text-center">Введите email и пароль</p>
                </div>
                {errorReason.value &&
                    <div class="alert alert-danger">{errorReason.value}</div>}
                {login.value && !login.value.includes("@") &&
                    <div class="alert alert-danger">Логин должен быть адресом почты!</div>}
                {password.value && repeatPassword.value && password.value != repeatPassword.value &&
                    <div class="alert alert-danger">Пароли должны совпадать!</div>}
                {password.value && password.value.length < 6 &&
                    <div class="alert alert-danger">Минимальное количество символов в пароле - 6!</div>}

                <input type="email" key="login" name="username" required class="form-control" placeholder="Логин" value={login.value} onInput={e => login.value = e.currentTarget.value} />
                <input type="password" key="pass" required class="form-control" placeholder="Пароль" value={password.value} onInput={e => password.value = e.currentTarget.value} />
                <input type="password" key="repeat" required class="form-control" placeholder="Повторите пароль" value={repeatPassword.value} onInput={e => repeatPassword.value = e.currentTarget.value} />

                <button onClick={() => register()} class="btn btn-primary">Создать аккаунт</button>
                <button onClick={() => changeForm("login")} class="text-decoration-none text-blue-400 hover:text-blue-600 transition-colors">Уже есть аккаунт? Войдите</button>
            </div>
        </div>

    )
}